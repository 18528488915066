// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("jquery-ui")

// On page load do
document.addEventListener("turbolinks:load", () => {

  // Prevent image dragging
  $('img').on('dragstart', function(event) { event.preventDefault(); });

  // To update the current time in the header
  updateTime()

  function updateTime() {
    var dateInfo = new Date();

    /* time */
    var hr, _min = (dateInfo.getMinutes() < 10) ? "0" + dateInfo.getMinutes() : dateInfo.getMinutes(), ampm = (dateInfo.getHours() >= 12) ? "PM" : "AM";

    // replace 0 with 12 at midnight, subtract 12 from hour if 13‐23
    if (dateInfo.getHours() == 0) {
       hr = 12;
    } else if (dateInfo.getHours() > 12) {
       hr = dateInfo.getHours() - 12;
    } else {
       hr = dateInfo.getHours();
    }

    var currentTime = hr + ":" + _min + ampm.toLowerCase();

    // print time
    document.getElementById("currentTime").innerHTML = currentTime;

    // update every
    var time = setTimeout(updateTime,5000);
  }

  // Page Loading Video
  loadPage()
  function loadPage() {
    var pageLoaded = false
    var timeLoaded = false
    $(window).on("load", function() {
      pageLoaded = true
      if (pageLoaded == true && timeLoaded == true) {
        $('.loader').css('opacity', '0')
        $('.loader').css('pointerEvents', 'none')
      }
    })
    setTimeout(function() {
      timeLoaded = true
      if (pageLoaded == true && timeLoaded == true) {
        $('.loader').css('opacity', '0')
        $('.loader').css('pointerEvents', 'none')
      }
    }, 1000);
  }

  // // Make draggable elements
  // $( function() {
  //   $(".draggable").draggable();
  // } );
  //
  // // Link out on arrow click
  // $('.button.arrow').click(function(e) {
  //   e.stopPropagation();
  //   if (document.getElementById('alicia').checked) {
  //     window.open('https://a-lee-c-a.com/','_blank');
  //   } else if (document.getElementById('audrey').checked) {
  //     window.open('https://www.instagram.com/audrey.chmielewski/','_blank');
  //   } else if (document.getElementById('bridget').checked) {
  //     window.open('http://bridgetmelville.com/','_blank');
  //   } else if (document.getElementById('james').checked) {
  //     window.open('https://jamesmeadowcroft.com/','_blank');
  //   } else if (document.getElementById('liz').checked) {
  //     window.open('http://lizluby.xyz/','_blank');
  //   } else if (document.getElementById('selena').checked) {
  //     window.open('http://selenarepanis.com/','_blank');
  //   } else {
  //     $('.collaborators .statusbar p').html('Select a Collaborator!')
  //   }
  // })
  //
  // // Change text on radio button click
  // $('input').click(function() {
  //   $('.collaborators .statusbar p').html('Visit Website <span>&rsaquo;</span>')
  // })
  //
  // // Heart Animation
  // $('.button.love').click(function(e) {
  //   e.stopPropagation();
  //   $('.hearts').append("<img src='images/hearts/0" + Math.floor(Math.random() * 10 + 1) + ".png' style='top: " + Math.random() * window.innerHeight + "px; left: " + Math.random() * window.innerWidth + "px; transform: rotate(" + (Math.random() * 30 - 15) + "deg);'>")
  // })
  //
  // // Close Window
  // $('.button.exit').click(function(e) {
  //   e.stopPropagation();
  //   $(this).parents('.window').toggle()
  // })
  //
  // // Open panel on mobile
  // if (window.innerWidth < 850) {
  //   $('.statusbar').click(function() {
  //     $(this).siblings('.content').slideToggle()
  //   })
  // }

})
